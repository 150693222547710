import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import cover from "../../images/procucers-cover.png";
import Newsletter from "../../components/Newsletter";
import image1 from "../../images/ambassadors/img1.png";
import image2 from "../../images/ambassadors/img2.png";
import image3 from "../../images/ambassadors/img3.png";
import image4 from "../../images/ambassadors/img4.png";

class Producers extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-producers">
        <SEO title="Our Producers" />
        <div className="cover-container">
          <div className="cover-img">
            <Img
              fluid={this.props.data.cover.childImageSharp.fluid}
              className="img-fluid"
              alt="Cover Image"
            />
          </div>
          <div className="cover-content">
            <div>
              <h1 className="hero-title">Our Producers</h1>
              {/*<h4 className="hero-sub-title">Excellence from the farm to your table</h4>*/}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container">
            <h2 className="title-4 text-center color-white">
              SPKL IS ALL ABOUT THE RELATIONSHIP
            </h2>
            <h2 className="title-4 text-center color-white">
              {" "}
              BETWEEN <span className="color-primary">PRODUCER</span> &{" "}
              <span className="color-primary">CONSUMER</span>
            </h2>
            <hr className="custom-hr" />
            <p className="color-white">
              We are extremely proud of the group of Aussie farmers we partner
              with to bring you the best tasting beef in Australia. All Speckle
              Park beef producers are individually selected based on cattle
              breeding and genetic markers as well as their commitment to
              sustainable production methods, low stress stock handling, care
              passion and integrity. Together we are collectively committed to
              total transparency, traceability and supply chain control and the
              associated technology that enables the tracing of SPKL beef back
              to the farmer who produced it, ensuring only the very best SPKL
              beef ends up on your plate.
            </p>
            <h3 className="title-5 text-center color-white mb-5">
              YOU CAN REALLY TASTE THE DIFFERENCE
            </h3>
          </div>
          {/* <div className="container">
          <h1 className="title-3 text-center color-primary">SPKL Ambassadors</h1>
          <hr className="custom-hr" />
          <p className="color-white text-center">Our farmers are the best in the business, with remarkable stories of their own</p>
          <div className="row my-5">
            <div className="col-md-3 text-center color-white">
              <img className="img-fluid" src={image1} />
              <h6 className="font-weight-bold mt-2">Peter Bishop</h6>
              <p>Ravenhills Farms</p>
            </div>
            <div className="col-md-3 text-center color-white">
              <img className="img-fluid" src={image2} />
              <h6 className="font-weight-bold mt-2">Olivia Dunham</h6>
              <p>Sunny Cattle Farms</p>
            </div>
            <div className="col-md-3 text-center color-white">
              <img className="img-fluid" src={image3} />
              <h6 className="font-weight-bold mt-2">William Bell</h6>
              <p>Bell & Sons Farming</p>
            </div>
            <div className="col-md-3 text-center color-white">
              <img className="img-fluid" src={image4} />
              <h6 className="font-weight-bold mt-2">Nina Sharp</h6>
              <p>NSW Farming Corp.</p>
            </div>
          </div>
        </div> */}
          <Newsletter />
        </div>
      </Layout>
    );
  }
}

export default Producers;

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "producers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
